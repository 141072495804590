<template>
    <iframe
        :src="`https://hrembeddedchat.ontp.app/DemoUseCase1.aspx?CurrentSessionId=${id}&EmbeddedMode=SPA&EncryptedDataHex=${encryptedDataHex}`"
        allow="camera;microphone" allowfullscreen class="chat"/>
</template>

<script>
import {defineComponent} from 'vue'
import {v4 as uuidv4} from 'uuid';

export default defineComponent({
    name: 'Chat',
    props: {
        encryptedDataHex: {
            required: true,
            type: String
        }
    },
    setup() {
        const id = uuidv4();
        const chatUrl = process.env.VUE_CHAT_IFRAME_DEV;

        return {id, chatUrl}
    }
})
</script>

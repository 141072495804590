<template>
    <button class="btn-float btn-trigger primary" @click="handleShowFloatView">
        <i class="fas fa-times px-8" v-if="showFloatView"></i>
        <span class="px-10 text-14" v-else>HR Helpdesk</span>
    </button>
    <transition name="bounce" mode="out-in">
        <div class="float-view" v-show="showFloatView">
            <div class="title-container" v-if="tab !== 'home'">
                <button class="back mr-15" @click="handleTab({tab: 'home', title: ''})">
                    <i class="fas fa-arrow-left"></i>
                </button>
                <h2 class="m-0">{{ viewTitle }}</h2>
            </div>
            <img src="https://tpcdn.azureedge.net/dev/ews/mytpLogo.svg" class="header__logo" alt="Teleperformance"/>

            <Home @handleTab="handleTab($event)" v-if="tab === 'home'"/>

            <div class="float-view__content float-view__content--chat" v-show="tab === 'chat'">
                <Chat :encryptedDataHex="encryptedDataHex" v-if="encryptedDataHex"/>
            </div>
            <div v-if="tab === 'submit'" class="float-view__content">
                <SubmitCase :categories="categories" @submitCase="submitCase($event)" :extensions="extensions"/>
            </div>

            <div class="pending" v-if="tab === 'pending'">
                <p>You have
                    <span class="text-primary">({{ allCases.length }})</span>
                    cases
                </p>
                <p v-if="allCases.length === 0">No pending cases</p>

                <li v-else className="category" v-for="(category, index) in allCases" :key="category.id">
                    <span className="category__title" style="margin: 0"><small> CN : <span
                        class="text-primary">{{ category.CaseId }}</span></small></span>
                    <span className="category__title"><small>{{ category.Classify2 }}</small></span>
                    <p :class="selectedCard === index && expanded === true ? 'category__option category__option--text tp-ellipsis' : 'category__option category__option--text tp-ellipsis tp-ellipsis--3' ">
                        {{ category.CaseComments }}</p>
                    <span v-if="category.CaseComments.length > 100 && selectedCard === index && expanded === true"
                          class="seemore" @click="seeLessInfo()">See less</span>
                    <span v-else-if="category.CaseComments.length > 100" class="seemore" @click="seeMoreInfo(index)">See more</span>
                    <p className="category__option category__option--date">{{ category.InsertDate }}</p>
                    <span className="category__status green" v-if="category.StateId === 'SO'"></span>
                    <span className="category__status yellow-2" v-else></span>
                </li>
            </div>
            <template v-if="tab === 'success'">
                <div class="float-view__content float-view__content--center">
                    <!--            <i :class="`fas fa-alert`"></i>-->
                    <p className="success__text">{{ message.first }}</p>
                    <p className="success__small">{{ message.second }}
                        <span>{{ message.ticket }}</span>
                    </p>
                    <button class="button button--primary" @click="tab = 'home' ">Accept</button>
                </div>
            </template>
            <div v-if="tab === 'loading' ">
                <Loading class="mt-30"/>
            </div>
        </div>
    </transition>
</template>
<script>
import {defineComponent, ref, watch} from 'vue'
import Home from '@/components/Home.vue';
import SubmitCase from '@/components/SubmitCase.vue';
import Chat from '@/components/Chat.vue';
import Loading from '@/components/Loading.vue';
import services from "@/api/services";
/*import axios from 'axios';

const URL = process.env.VUE_APP_CASES_API;*/

export default defineComponent({
    name: 'App',
    props: {
        token: String
    },
    components: {SubmitCase, Chat, Home, Loading},
    emits: ['invalid-token'],
    setup(props, {emit}) {
        const showFloatView = ref(false);
        const tab = ref('home');
        const viewTitle = ref('');
        const message = ref({first: '', second: '', ticket: ''});
        const search = ref('');
        const categories = ref([]);
        const extensions = ref([]);
        const allCases = ref([]);
        const selectedCard = ref();
        const expanded = ref(false);
        const encryptedDataHex = ref();

        const handleShowFloatView = () => showFloatView.value = !showFloatView.value;

        const handleTab = (to) => {
            tab.value = to.tab;
            viewTitle.value = to.title;
            if (tab.value === 'pending') getAllCases();
        }

        const seeMoreInfo = (index) => {
            selectedCard.value = index;
            expanded.value = true;
        }
        const seeLessInfo = () => {
            selectedCard.value = undefined;
            expanded.value = false;
        }

        async function submitCase(event) {
            try {
                tab.value = 'loading'
                const caseNumber = (await services.setNewCase(event)).data;
                message.value.first = 'Your case has been processed successfully!'
                message.value.second = 'Case ID :'
                message.value.ticket = caseNumber.caseNumber
            } catch (e) {
                message.value = {};
                message.value.first = e.response.data.message;
                if (e.satusCode === 401) emit('invalid-token')
            } finally {
                tab.value = 'success'
            }
        }

        async function getCategories() {
            try {
                categories.value = (await services.getCategoryStructure()).data;
            } catch (e) {
                if (e.satusCode === 401) emit('invalid-token')
                console.log('');
            }
        }

        async function getExtensions() {
            try {
                extensions.value = (await services.getValidExtensions()).data;
            } catch (e) {
                if (e.satusCode === 401) emit('invalid-token')
                console.log('');
            }
        }

        async function getDataHex() {
            try {
                encryptedDataHex.value = (await services.getDataHex()).data;
            } catch (e) {
                console.log('');
            }
        }

        watch(
            () => props.token,
            async () => {
                services.setToken(props.token);
                await triggerData();
            }
        );

        async function triggerData() {
            await getDataHex();
            await getCategories();
            await getExtensions();
        }

        async function getAllCases() {
            tab.value = 'loading';
            try {
                allCases.value = (await services.getAllCasesByUser()).data;
            } catch (e) {
                if (e.satusCode === 401) emit('invalid-token')
            } finally {
                tab.value = 'pending'
            }
        }

        services.setToken(props.token);

        return {
            showFloatView,
            handleShowFloatView,
            handleTab,
            tab,
            viewTitle,
            search,
            submitCase,
            message,
            allCases,
            categories,
            seeMoreInfo,
            expanded,
            selectedCard,
            seeLessInfo,
            extensions,
            encryptedDataHex
        }
    }
})
</script>

<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Noto Sans', sans-serif;
}

body {
    padding: 0;
    margin: 0;
    z-index: 999999;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: lightgray;
    border-radius: 10px;
}

.bounce-enter-active {
    transition: all 0.3s ease-out;
}

.bounce-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.bounce-enter-from, .bounce-leave-to {
    transform: translateY(30px);
    opacity: 0;
}

.pr-15 {
    padding-right: 15px !important;
}

.mr-15 {
    margin-right: 15px;
}

.ml-15 {
    margin-left: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.m-0 {
    margin: 0;
}

.px-10 {
    padding: 0 10px;
}

.px-8 {
    padding: 0 8px;
}

.text-bold {
    font-weight: 700;
}

.text-center {
    text-align: center;
}

.flex {
    display: flex;
    width: 100%;
}

.flex--align-items-center {
    align-items: center;
}

.flex--justify-between {
    justify-content: space-between;
}

.flex--space-around {
    justify-content: space-around;
}

.flex--center {
    justify-content: center;
    align-items: center;
}

.btn-float {
    width: auto;
    height: 60px;
    min-width: 60px;
    line-height: 30px;
    display: inline-block;
    border: none;
    font-size: 18px;
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: 0.3s;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
    z-index: 999999;
}

.btn-float:hover {
    text-decoration: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), 0 4px 15px rgba(0, 0, 0, 0.13);
}

.btn-float:active, .btn-float:focus {
    outline: none;
}

.btn-float {
    margin-left: 5px;
}

.text-16 {
    font-size: 16px;
    font-weight: bold;
}

.text-14 {
    font-size: 14px;
    font-weight: bold;
}

.text-12 {
    font-size: 12px;
    font-weight: bold;
}

.primary {
    background: rgb(120, 0, 150);
}

.float-view {
    position: fixed;
    bottom: 100px;
    right: 40px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);
    max-height: 100vh;
    max-width: 100vw;
    height: 600px;
    width: 380px;
    padding: 1.25rem;
    z-index: 999999;
}

.float-view.active {
    display: block;
    animation-name: appearFloatView;
    animation-duration: 0.3s;
}

.float-view__content {
    margin-top: 20px;
}

.float-view__content--center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.header {
    position: relative;
}

.header__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
}

.header__logo {
    position: absolute;
    top: 15px;
    right: 15px;
}

.list {
    list-style: none;
    margin: 20px 0 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    overflow-x: hidden;
    height: 450px;
    scrollbar-color: darkgray lightgray;
    scrollbar-width: thin;
}

.list__item {
    height: 120px;
    padding: 15px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    transition: all 0.3s;
    cursor: pointer;
}

.list__item:hover {
    background-color: #F4F4F8;
}

.list__item__title {
    display: inline-block;
    margin: 0;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-size: 20px;
}

.list__item__index {
    font-weight: 700;

}

.list__item__text {
    margin-top: 10px;
    line-height: 18px;
    font-size: 14px;
    color: #4D4D4D;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.search {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    margin-top: 10px;
    top: -5px;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s;
    opacity: 0;
    z-index: 100;
    background-color: #fff;
}

.search.active {
    top: 45px;
    visibility: visible;
    pointer-events: all;
    opacity: 1;

}

.search__input {
    width: 90%;
    border: none;
    outline: none;
}

.search i {
    padding-left: 5px;
}

.back {
    color: rgb(120, 0, 150);
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 0;
}

.title-container {
    display: flex;
    align-items: center;
}

.list--category {
    padding: 0 0 15px 0;
    margin: 15px 0 0 0;
    list-style: none;
    overflow: auto;
    overflow-x: hidden;
    height: 400px;
    scrollbar-color: darkgray lightgray;
    scrollbar-width: thin;
}

.pending {
    padding: 0 15px 15px 0;
    margin: 15px 0 0 0;
    list-style: none;
    overflow: auto;
    overflow-x: hidden;
    height: 490px;
    scrollbar-color: darkgray lightgray;
    scrollbar-width: thin;
}

.category {
    border: 2px solid #F4F4F8;
    list-style: none;
    position: relative;
    padding: 10px;
}

.category p {
    padding: 0;
    margin: 0;
}

.category.selected {
    background-color: #F4F4F8;
    list-style: none !important;
    cursor: default;
    border-radius: 4px !important;
}

/*.category:hover {
    background-color: #F4F4F8;
}*/

.category__title {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 15px;
    display: block;
}

.category__option {
    margin: 0;
    margin-top: 10px;
    padding-left: 15px;
    font-size: 14px;
    color: #6C6C6C;
    letter-spacing: 0.2px;
}

.category__option.category__option--date {
    font-size: 11px;
}

.category__option.category__option--text {
    color: #4D4D4D;
    font-size: 15px;
    padding-right: 20px;
}

.category__status {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.green {
    background-color: #27ae60
}

.yellow {
    background-color: #ffeaa7;
}

.yellow-2 {
    background-color: #f39c12
}

.red {
    background-color: #e74c3c
}

.gray {
    background-color: #bdc3c7;
}

.orange {
    background-color: #e67e22;
}

.label {
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

.input {
    width: 100%;
    border: 2px solid #F4F4F8;
    border-radius: 4px;
    padding: 0.5rem;
    transition: all 0.3s;
}

.input:focus, .search:focus {
    border-color: rgb(120, 0, 150);
    outline: none;
}

.textarea {
    width: 100%;
    resize: none;
    height: 330px;
    border: 2px solid #F4F4F8;
    border-radius: 4px;
    transition: all 0.3s;
    padding: 8px;
}

.textarea:focus {
    border-color: rgb(120, 0, 150);
    outline: none;
}

.button {
    padding: .5rem .75rem;
    border-radius: 4px;
    outline: none;
    border: none;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.4s;
}

.button--primary {
    background-color: rgb(120, 0, 150);
    color: #fff;
}

.button--primary.button--primary--inverted {
    background-color: rgba(120, 0, 150, 0.2);
    color: rgba(120, 0, 150, 1);
}

.button--pink {
    background-color: rgba(255, 0, 130, 0.2);
    color: #FF0082;
}

.button--pink.active, .button--pink:hover {
    background-color: rgba(255, 0, 130, 1);
    color: #fff;
}

.button--right {
    float: right;
}

.button--options {
    width: 100%;
    padding: .75rem;
    font-size: 15px;

}

.button--options:nth-child(1) {
    margin-right: 15px;
}

.helper {
    margin-top: 20px;
    height: 400px;
    border: 1px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 4px;
}

.helper__text {
    text-align: center;
    color: #6C6C6C;
    font-weight: 600;
    font-size: 13px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 70px;
    height: 70px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 54px;
    height: 54px;
    margin: 8px;
    border: 6px solid rgb(120, 0, 150);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(120, 0, 150) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.chat {
    height: 510px;
    width: 340px;
    border: none;
}

@media only screen and (max-height: 670px) and (min-width: 450px) {
    .float-view {
        height: 480px;
    }

    .float-view__content {
        height: calc(100% - 100px);
    }

    .float-view__content--chat {
        height: calc(100% - 50px);
    }

    .list {
        height: 100%;
        overflow-y: auto;
    }

    .list--category {
        height: 300px;
    }

    .helper {
        height: 250px
    }

    .pending {
        height: calc(100% - 65px);
    }

    .chat {
        height: 100%;
        width: 340px;
        border: none;
    }

    .textarea {
        height: 235px;
    }

    .dropdown__list {
        height: 220px !important;
    }
}

@media only screen and (max-width: 449px) {
    .btn-float {
        right: 3px;
        bottom: 10px;
        height: 40px;
        min-width: 40px;
        line-height: 40px;
    }

    .float-view {
        bottom: 0;
        right: 0;
        z-index: 1000;
        height: 100vh;
        width: 100vw;
    }

    .float-view__content {
        height: 100%;
    }

    .btn-float {
        z-index: 2000;
    }

    .list {
        height: calc(100vh - 120px - 1.75rem);
        padding-bottom: 15px;
    }

    .list--category {
        height: calc(100vh - 200px - 1.75rem);
        padding-bottom: 15px;
    }

    .pending {
        height: calc(100vh - 130px);
        padding-bottom: 15px;
    }

    .list__item__title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }

    .chat {
        height: 92%;
    }

    .helper {
        height: 60vh;
    }
}

.success__text {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
}

.success__small {
    font-size: 18px;
    color: #6C6C6C;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 50px;
}

.success__small span {
    color: rgb(120, 0, 150);
    font-weight: 700;
}

.text-primary {
    color: rgb(120, 0, 150);
    font-weight: 700;
}

.error {
    font-size: 13px;
    color: #e74c3c;
    margin: 0;
    font-weight: 700;
    margin-bottom: 10px;
}

.tp-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    max-width: 100%;
}

.tp-ellipsis--1 {
    -webkit-line-clamp: 1;
}

.tp-ellipsis--2 {
    -webkit-line-clamp: 2;
}

.tp-ellipsis--3 {
    -webkit-line-clamp: 3;
}

.seemore {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    font-size: 11px;
    padding-left: 15px;
}

.dropdown {
    width: 100%;
    background-color: transparent;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    color: rgb(189, 189, 189);
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.dropdown__list {
    width: 100%;
    position: absolute;
    width: 100%;
    background-color: #fff;
    height: 300px;
    overflow: auto;
    list-style: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0;
    margin: 0;
    top: -5px;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s;
    opacity: 0;
    z-index: 100;
}

.dropdown__list.active {
    top: 93px;
    visibility: visible;
    pointer-events: all;
    opacity: 1;
}

.is-relative {
    position: relative;
}

#submitFile {
    display: none;
}

.button--file {
    width: 40px;
    display: inline-block;
}

.attached {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 13px;
}

</style>
<template>
    <header class="header">
          <h2 class="m-0 header__title">HR Helpdesk</h2>
          <div class="search">
            <input placeholder ="Search here..." class="search__input" v-model="search"/>
            <i className="fas fa-search"/> 
          </div>
        </header>
        <div class="float-view__content">
          <p v-if="optionsFiltered.length === 0">No results found...</p>
          <transition-group class="list" :class="optionsFiltered.length >= 3 && 'pr-15'" tag="ul" name="bounce" mode="out-in" v-else>
              <li class="list__item" v-for="option in optionsFiltered" :key="option.tab" @click="$emit('handleTab',option)">
                <h2 class="list__item__title">
                  <span class="list__item__index">{{option.index}}. </span>
                  {{option.title}}
                  </h2>
                <p class="list__item__text">{{option.description}}</p>
              </li>
          </transition-group>
        </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'

export default defineComponent({
    setup() {
    const search = ref('');

        const options = ref([
      {
        title: 'Live chat',
        description: 'You can connect directly with one of our many representitiaves to help you with whatever problem you may have.',
        tab: 'chat',
        index: '01'
      },
      {
        title: 'Submit a case',
        description: 'Fill out a short form to tell us about the issue that you are having and a representative will reach out to you to help.',
        tab: 'submit',
        index: '02'
      },
      {
        title: 'View pending cases',
        description: 'Easily check in on the status of a previous case to see how your issue is being addressed.',
        tab: 'pending',
        index: '03'
      },
    ]);
    const optionsFiltered = computed( () => {
      return options.value.filter( (option) => option.title.toLowerCase().indexOf(search.value.toLowerCase()) !== -1)
    });
    return {options, optionsFiltered, search}
    },
})
</script>

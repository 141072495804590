import axios from 'axios';

const URL = process.env.VUE_APP_CASES_API;
let token;

//Access token from store
function getHeaders() {
    //const token = store.getters['user/getAccessTokens'];
    //const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ik1yNS1BVWliZkJpaTdOZDFqQmViYXhib1hXMCIsImtpZCI6Ik1yNS1BVWliZkJpaTdOZDFqQmViYXhib1hXMCJ9.eyJhdWQiOiI0ZmMxNTg2OC02OGRjLTQyNjUtOGVlOC05ZWQ0MDU3NDBmOGMiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC82MzhmY2JhZi1iYTRjLTQzZTEtYWRhZS01NDc1Yzk3MGZlMTAvIiwiaWF0IjoxNjQ0OTU3MzM3LCJuYmYiOjE2NDQ5NTczMzcsImV4cCI6MTY0NDk2MTM5NywiYWNyIjoiMSIsImFpbyI6IkFWUUFxLzhUQUFBQS8wNDBMNTVhV2ppTXZKdTdldEpiVi8yQXN6SVN0WHRwaG1Nd2U5cDdSbWU1UjF4ZC9HemZOYmdDU0JnTmM2RWwzbnJIM3hDZVFGMlZDaEhPcGwwdTNPODBpY2tCOHk0UnBONHdkbTl5WjYwPSIsImFtciI6WyJwd2QiLCJtZmEiXSwiYXBwaWQiOiI2NDZiMmYyNy0zZTg2LTRkOGMtYmEyZi02MjcxMTU3Mzg1MjciLCJhcHBpZGFjciI6IjEiLCJmYW1pbHlfbmFtZSI6IlZhc3F1ZXogTW9yZWxvIiwiZ2l2ZW5fbmFtZSI6IkhhbmlhIiwiaXBhZGRyIjoiMTkxLjg4LjE2LjE3MSIsIm5hbWUiOiJIYW5pYSBWYXNxdWV6IE1vcmVsbyIsIm9pZCI6IjU1YjdhYzVhLWI1OTUtNDNhOC04ZDQ0LWFiNjQ1NTBkYmU5ZCIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS0xMzc3MjMxMDMwLTI3NTM1MjA4NDYtMzQ5NDc1MTQ4MC0yODA2NyIsInJoIjoiMC5BU0FBcjh1UFkweTY0VU90cmxSMXlYRC1FR2hZd1VfY2FHVkNqdWllMUFWMEQ0d2dBTG8uIiwic2NwIjoiQ2FzZXMuUmVhZFdyaXRlIiwic3ViIjoiRmJHZXZCRmpwd3E1c0JVXzlnSl9pdmVmam5HQXNISEhhdFJXUk5paFNNUSIsInRpZCI6IjYzOGZjYmFmLWJhNGMtNDNlMS1hZGFlLTU0NzVjOTcwZmUxMCIsInVuaXF1ZV9uYW1lIjoidmFzcXVlem1vcmVsby41QG5sc2EudGVsZXBlcmZvcm1hbmNlLmNvbSIsInVwbiI6InZhc3F1ZXptb3JlbG8uNUBubHNhLnRlbGVwZXJmb3JtYW5jZS5jb20iLCJ1dGkiOiJOUlBTZzFvbEpFV1BNVWZkUkdGVEFBIiwidmVyIjoiMS4wIn0.ikOVhpTgDwebDC58HmLDiUL1bf536x_mHekuZDDHsl6akBK7-bB5aM0qpvlsnT3MVqGvu6L2TGqDEbDaTHFv8BNKNFKfMcqn415Ji8JkRLoBDEOOafbA4I53izfY_WBQAo06_b7JkhTyclm5BIBWGBUNwUR4294Jt3pjbmorNSedXfP6A_KRpn_L3GyUc5kWSiIERCEobAjYX2TBxkEqAp1OhUA8ofcVVDg_HW0nMVYP5BBjyMMIQAyjSLtO-Sr4Tek9Z_D6DR0gsX9KT1x4IF6j8p4U7yLqdXOydBzUc94LJ58de-pty2kLmfhwIJvLti1X8qSWMF9x4lubADLIfA"
    return {
        'Authorization': `Bearer ${token}`,
        'Accept-Language': 'es'
    };
}

export const api = axios.create({
    baseURL: `${URL}api/`,
    withCredentials: true,
});

/*api.interceptors.response.use(function (response)
{
    return response;
}, async function (error)
{
    if (error)
    {
        const originalRequest = error.config;
        if (!originalRequest.url.startsWith('public') && error.response.status === 401)
        {
            await store.dispatch('user/fetchAccessTokens', null, {root: true});
            const accessToken = getHeaders();
            originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
            return api(originalRequest);
        }
        else
        {
            return Promise.reject(error);
        }
    }
});*/

function validateToken(token, callback) {
    if (token) {
        return callback;
    }
    return {data: undefined};
}

export default {
    URL,
    getValidExtensions() {
        return validateToken(token, api.get(`v1/extensions`, {headers: getHeaders()}));
    },
    getDataHex() {
        return validateToken(token, api.get('/v1/chatToken', {headers: getHeaders()}));
    },
    getAllCasesByUser() {
        return validateToken(token, api.get('v1/cases', {headers: getHeaders()}));
    },
    getCaseById(id) {
        return validateToken(token, api.get(`v1/cases/${id}`, {headers: getHeaders()}));
    },
    getCategoryStructure() {
        return validateToken(token, api.get(`v1/types`, {headers: getHeaders()}));
    },
    setNewCase(params) {
        return validateToken(token, api.post('v1/cases', params, {headers: getHeaders()}));
    },
    setToken(propsToken) {
        token = propsToken;
    }
};

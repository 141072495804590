<template>
        <div v-if="!selectedCategory">
            <p class="mb-15 mt-15 text-bold">Category</p>
        </div>

        <Select :categories="categories" @selectedCategory="selectedCategory = $event"/>
        <div v-if="selectedCategory">
            <Loading v-if="loading"/>
            <div v-else>
                <textarea class="mb-15 mt-30 textarea" placeholder="Your comments here..." v-model="comment"></textarea>
                <p v-if="error" class="error">{{error}}</p>
                <div class="flex flex--align-items-center flex--justify-between">
                    <div class="flex flex--align-items-center">
                        <label>
                            <input type="file" id="submitFile" name="file" @change="getFileInput" :accept="extensionsAccept()" />
                            <div class="button button--primary button--primary--inverted button--file">
                                <i class="fas fa-paperclip" />
                            </div>
                        </label>
                        <span class="attached ml-15 tp-ellipsis">{{file && file.name}}</span>
                    </div>
                    <button class="button button--primary button--right" @click="validateCaseCreation">Submit case</button>
                </div>
            </div>
        </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Loading from './Loading.vue';
import Select from './Select.vue';
export default defineComponent({
    name: 'SubmitCase',
    components: { Loading, Select },
    props: {
      categories:{
        type: Array
      },
      extensions:{
          type: Array
      }
    },
    emits: ['submitCase'],
    setup (props, {emit}) {
        const comment = ref('');
        const selectedCategory = ref(null);
        const error = ref(null);
        const loading = ref(false);
        const file = ref(undefined);

        const validateCaseCreation = async () => {
            if(comment.value.trim().length === 0 || comment.value.length === 0) error.value = 'Please submit a valid comment'
            else {
                let name;
                if(file.value){
                    name = file.value.name;
                    const data = await readFile(file.value);
                    file.value = {name, data}
                }
                emit('submitCase', { comments: comment.value, type: selectedCategory.value.id, attachment: file.value})
                error.value = null;
            }
        }

        const getFileInput = async (e) => {
            if(e.target.files[0].size > 2097152){
                file.value = undefined;
                error.value = 'File must be 2MB maximum'
                e.target.files[0] = undefined;
            }else{
                error.value = null;
                file.value = e.target.files[0];
            }
        }

        const readFile = function (blob)
        {
        return new Promise((resolve, reject) =>
        {
            const reader = new FileReader;
            reader.onerror = reject;
            reader.onload = () =>
            {
            resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });
        };

        const extensionsAccept = () => props.extensions.map( ext => `.${ext}`).join(',');
        
        return {selectedCategory, loading, comment, validateCaseCreation, error, getFileInput, file, extensionsAccept}
    }
})
</script>

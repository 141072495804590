<template>
<div class="is-relative" style="background-color: #fff">
    <button class="dropdown tp-ellipsis tp-ellipsis--1" @click="active = !active">{{selectedCategory ? selectedCategory.description : 'Select a category'}}<i class="fa fa-chevron-down"/></button>
        <div class="search" :class="active ? 'active': ''">
            <input placeholder ="Search here..." class="search__input" v-model="search"/>
            <i className="fas fa-search"/> 
        </div>
        <transition-group class="dropdown__list" :class="[active ? 'active': '']" tag="ul" name="bounce" mode="out-in">
            <div v-if="categories">
                <li className="category" v-for="category in categoriesFiltered" :key="category.id" @click="selectedCategoryHandler(category)">
                    <p className="category__option">{{category.description}}</p>
                </li>
            </div>
        </transition-group>
</div>
</template>

<script>
import { defineComponent, ref, computed} from 'vue'

export default defineComponent({
    name: 'Select',
    props: {
      categories:{
        type: Array
      }
    },
    emits: ['selectedCategory'],
    setup(props, {emit}) {
        const active = ref(false);
        const selectedCategory = ref();
        const search = ref('');
        const categoriesFiltered = computed( () => {
        return props.categories.filter( (category) => {
            return category.description.toLowerCase().indexOf(search.value.toLowerCase()) !== -1
        })
        });
        const selectedCategoryHandler = (category) => {
            emit('selectedCategory', category)
            active.value = false;
            selectedCategory.value = category;
        }
        return { search, categoriesFiltered, active, selectedCategoryHandler, selectedCategory}

    }
})
</script>
